<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field label="ID" v-model="iotid"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field label="Auth" v-model="auth_code"></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="validateIot">Check Status</v-btn>
    <br><br>

    <v-alert type="error" v-if="errorMessage" class="mt-3">
        {{ errorMessage }}
    </v-alert>

    <!-- Conditionally display the package dropdown and add button -->
    <div v-if="isValidated">

      <v-alert
        :type="iotStatus === 'In-Service' ? 'success' : 'info'"
      >
        {{ iotStatus === 'In-Service' ? 'In-Service' : 'Pre-Service. Ready for Immediate Use.' }}
      </v-alert>
      
      <br>
      <h4> Active package(s) </h4>
      
      <v-list v-if="activePackages && activePackages.length > 0">
        <template v-for="(pkg, index) in activePackages" :key="`active-package-${index}`">
          <!-- Data Usage -->
          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-web</v-icon> <!-- Data Usage Icon -->
            </template>
            <v-list-item-title>
              <span style="color: blue-grey;">Data Remaining: </span>
              <span style="font-weight: bold; color: indigo;">{{ pkg.dataUsageRemaining }}</span>
            </v-list-item-title>
          </v-list-item>
          
          <v-divider inset></v-divider>
          
          <!-- Expiry Date -->
          <v-list-item>
            <template v-slot:prepend>
              <v-icon>mdi-calendar</v-icon> <!-- Expiry Date Icon -->
            </template>
            <v-list-item-title>
              <span style="color: blue-grey;">Data Expires: </span>
              <span style="font-weight: bold; color: indigo;">{{ pkg.dateExpiry }}</span>
            </v-list-item-title>
          </v-list-item>

          <!-- Divider between packages, but not after the last package -->
          <v-divider v-if="index < activePackages.length - 1"></v-divider>
        </template>
      </v-list>
      
      <v-divider ></v-divider>
      <br>      
      
      <v-list>
        <v-list-item v-for="(pkg, index) in nonActivePackages" :key="`nonactive-${index}`">
          <!-- Use prepend slot for the icon -->
          <template v-slot:prepend>
            <v-icon>mdi-database-clock</v-icon> 
          </template>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">
              Activate <span style="font-weight: bold;"> {{ pkg.dataUsageRemaining }} </span>
              by <span style="font-weight: bold;"> {{ pkg.dateExpiry }}  </span>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
      
                                       
      <br>
 
      <h4> Add a new package </h4>
      <v-radio-group v-model="selectedPackage" @change="handlePackageChange" color="success" :mandatory="true">
        <v-radio
          v-for="option in packageOptions"
          :key="option.pkgname"
          :label="`${option.description} - ${option.pkgcost}`"
          :value="option"
          :style="{ color: '#00695C', font: bold }"
        ></v-radio>
      </v-radio-group>

      <!-- Success Message -->
      <v-alert
        v-if="showSuccessMessage"
        type="success"
        dismissible
        @input="showSuccessMessage = false"
      >
        {{ successMessage }}
      </v-alert>
      <br>
      <!-- PayPal Button -->
      <PayPalButton 
        v-if="selectedPackage && selectedPackage.pkgcost"
        :packageOption="selectedPackage"
        @payment-success="handlePaymentSuccess"
      />
      
      <br>
      <v-divider ></v-divider>
      <br>   

      <v-expansion-panels>
        <v-expansion-panel v-for="(country, index) in cdr_month.country_breakdown" :key="`country-${index}`">
          <v-expansion-panel-title>
            <v-avatar size="20" class="mr-2">
              <img :src="`https://flagcdn.com/16x12/${getTwoLetterCode(country.country)}.png`">
            </v-avatar>
            <span style="font-weight: bold; color: teal;">
            {{ country.country }} networks used in {{ currentMonth }}
            </span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div style="height: 200px; width: 100%;">              
              <bar-chart :chart-data="prepareChartData(country)" :chart-options="chartOptions"></bar-chart>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>      

    </div> 

    <!-- Data Table 
    <v-data-table :headers="headers" :items="items" class="mt-3"></v-data-table>
    -->
  </v-container>
</template>

<script>
import axios from 'axios';
import { getApiBaseUrl } from '@/api/apiConfig';
import BarChart from './BarChart';
import PayPalButton from './PayPalButton';

axios.defaults.headers.common['X-API-Key'] = process.env.VUE_APP_API_KEY;

export default {
  name: 'IoTDash',
  components: {
    BarChart,
    PayPalButton
  },  
  data() {
    return {
      iotid: '',
      auth_code: '',
      errorMessage: '',
      isValidated: false,
      selectedPackageId: null, // s24      
      selectedPackage: null, // To hold the selected package option
      packageOptions: [],
      pkgstatus: [],
      iotStatus: null,
      dataUsageRemaining: null,
      dateExpiry: null,
      validationResults: {}, // This will be populated with the API response
      successMessage: '',
      showSuccessMessage: false,      
      cdr_month: { country_breakdown: [], total_data_usage_MB: 0 },      
      // Define table headers
      headers: [
        { text: 'Key', value: 'key' },
        { text: 'Value', value: 'value' },
      ],
      items: [], // Data to be displayed in the table
      
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: { // Correct scale configuration for Chart.js v3+
            beginAtZero: true,
            title: {
              display: true,
              text: 'Usage (%)'
            }
          },
          y: { // This axis will hold your labels
            title: {
              display: true,
              text: 'Networks'
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Network Usage'
          }
        },
        indexAxis: 'y', // For horizontal bar chart
      }, // chartoptions
        
    };
  },

  mounted() {
    document.title = 'eSIMera Portal';
  },
  
  computed: {  
    currentMonth() {
      return new Date().toLocaleString('default', { month: 'long' });
    },  
    activePackages() {
      // Assuming 'status' field exists and 'ACTIVE' denotes an active package
      return this.pkgstatus.filter(pkg => pkg.status === 'ACTIVE');
    },
    nonActivePackages() {
      return this.pkgstatus.filter(pkg => pkg.status !== 'ACTIVE');
    },
   
  },

  methods: {
    validateIot() {

      const baseURL = getApiBaseUrl();

      axios.post(`${baseURL}/rc/v1/check-iot`, { iotid: this.iotid, auth_code: this.auth_code })
        .then(response => {
          // Handle success
          this.validationResults = response.data;
          this.isValidated = true;
          console.log('Validation Results:', this.validationResults);
          
          // Extract and update packageOptions dynamically from the response
          if (response.data.packageOptions && Array.isArray(response.data.packageOptions)) {
            this.packageOptions = response.data.packageOptions.flat();
            console.log('Package Options:', this.packageOptions);
            this.selectedPackage = this.packageOptions[0];
          } else {
            console.warn('packageOptions is not an array or is undefined');
            this.packageOptions = [];
          }


          // Update IoT status information
          this.iotStatus = response.data.simStatus || 'No status available';
          this.pkgstatus = response.data.packageStatus || [{dataUsageRemaining: 'No package found', dateExpiry: ''}];
          this.cdr_month = response.data.cdrCurMonth || { country_breakdown: [], total_data_usage_MB: 0 };

          // Transform the validationResults object into an array of items
          this.items = Object.keys(this.validationResults).map(key => ({
            key: key,
            value: this.validationResults[key],
          }));
          this.errorMessage = ''; // Clear any previous error message
        })
        .catch(error => {
          // Handle error
          console.error(error);
          this.items = []; // Reset items on error
          this.isValidated = false;
          this.iotStatus = null;
          this.pkgstatus = [{dataUsageRemaining: 'Error fetching packages', dateExpiry: ''}];
          if (error.response && error.response.data && error.response.data.error) {
            this.errorMessage = error.response.data.error;
          } else {
            this.errorMessage = "An unexpected error occurred. Please try again.";
          }
      });
    },
    
    handlePaymentSuccess(details) {
      // Handle the successful payment response here, e.g., call your API
      console.log('Payment Success:', details);
      
      const baseURL = getApiBaseUrl();

      axios.post(`${baseURL}/rc/v1/add-package`, { iotid: this.iotid, pkgname: this.selectedPackage.pkgname })
        .then(response => {   
          this.addpkgResult = response.data;
          console.log('Add Package Result:', this.addpkgResult);    
          if (response.data.success) {
            this.successMessage = response.data.success;
            this.showSuccessMessage = true;
            // hide the message after a few seconds
            setTimeout(() => {
              this.showSuccessMessage = false;
              }, 10000); // Hide after 5 seconds
          }              
        })
        .catch(error => {
          // Handle error
          console.error(error);
          if (error.response && error.response.data && error.response.data.error) {
            this.errorMessage = error.response.data.error;
          } else {
            this.errorMessage = "An unexpected error occurred. Please try again.";
          }
      });
                 
    }, // payment

    addPackage() {
      // Example of handling the selected package
      if (!this.selectedPackage) {
        alert('Please select a package.'); // Simple validation
        return;
      }
      console.log('Adding package:', this.selectedPackage);
      // Here, you would typically make an API call to your backend 
    },

    selectPackage(option) {
      // Handle the package selection, maybe trigger payment process
      console.log('Selected package:', option);
      // Proceed with payment and further API call with option.pkgid
    },

    prepareChartData(country) {
      // console.log("Received country data:", country); // Log the received data

      if (!country || !country.networks) {
        console.log("Country or networks data is missing."); // Log if data is missing
        return { labels: [], datasets: [] }; // Return empty chart data if missing
      }

      const backgroundColors = [
        'rgba(30, 136, 229, 0.5)', // Color for the first network/bar
        'rgba(124, 77, 255, 0.5)', // Color for the second network/bar
        'rgba(142, 36, 170, 0.5)', // Extra color
        'rgba(128, 203, 196, 0.5)'  // Extra color
      ];
      
      // Assuming country.networks exists and contains data...
      const labels = country.networks.map(network => network.network);
      const data = country.networks.map(network => (network.data_usage_MB / country.country_data_usage_MB * 100).toFixed(2));

      const chartData = {
        labels,
        datasets: [{
          label: `${country.country} Network Usage`,
          data,
          backgroundColor: backgroundColors,
          // borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 0
        }]
      };

      console.log("Prepared chartData:", chartData); // Log the prepared chart data
      return chartData;
    },
    

    getTwoLetterCode(threeLetterCode) {
      const iso3166Alpha3toAlpha2 = {
        USA: 'us',
        GBR: 'gb',
        CAN: 'ca',
        FRA: 'fr',
        DEU: 'de',
        // Add more mappings as needed
      };

      return iso3166Alpha3toAlpha2[threeLetterCode] || '';
    },

  }, // methods


  
};
</script>

<style scoped>
.title-subtitle-container {
  margin-right: 20px; /* Adjust this value to set the fixed distance */
}
.bold-green-text {
  font-weight: bold;
  color: green; /* Adjust the color as needed */
}
.list-item-content {
  padding-right: 20px; /* Adds spacing between the text and the button */
  margin-bottom: 16px;
}
</style>
